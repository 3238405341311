import { Toaster } from 'react-hot-toast';
import AuthForm from './AuthForm';

export default function AuthenticationImage() {
  return (
    <>
      <div className="px-4 py-20 m-auto max-w-xl w-full">
        <AuthForm />
      </div>
      <Toaster />
    </>
  );
}
