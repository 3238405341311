import Auth from '@/components/Auth';

export { Page };

function Page() {
  return (
    <>
      <Auth />
    </>
  );
}
